export const CONFIG = Object.freeze({
  isCsrfRequired: !!Number(process.env.NEXT_PUBLIC_IS_CSRF_REQUIRED),
  appName: process.env.NEXT_PUBLIC_APP_NAME ?? 'PayToMe',
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL ?? 'http://localhost:8000/api',
  defaultRedirectUrl: process.env.NEXT_PUBLIC_DEFAULT_REDIRECT_URL ?? 'http://localhost:3060',
  cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'localhost',
  publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL ?? 'http://localhost:3050',
  myAccountUrl: process.env.NEXT_PUBLIC_MY_ACCOUNT_URL ?? 'http://localhost:3060',
  authUrl: process.env.NEXT_PUBLIC_ACCOUNTS_URL ?? 'http://localhost:3100',
  vendorUrl: process.env.NEXT_PUBLIC_VENDOR_URL ?? 'http://localhost:3110',
  merchantUrl: process.env.NEXT_PUBLIC_MERCHANT_URL ?? 'http://localhost:3120',
  authToken: process.env.NEXT_PUBLIC_AUTH_TOKEN ?? '_at',
  willAllowUnauthorizedTls: !!Number(process.env.NODE_TLS_REJECT_UNAUTHORIZED ?? '0'),
});
